// material-ui
import React from 'react';
import { Button, Grid, MenuItem, Snackbar, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getTaskExport } from 'services/task';
import { Link } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import Alert from '@mui/material/Alert';
import AnimateButton from 'ui-component/extended/AnimateButton';

// ==============================|| Index ||============================== //
const ReportTask = () => {
    const path = window.location.pathname;
    const dispatch = useDispatch();
    const [success, setSuccess] = React.useState('');
    const initialDate = useSelector((state) => state.report_task.initialDate);
    const finalDate = useSelector((state) => state.report_task.finalDate);
    const moduleOs = useSelector((state) => state.report_task.moduleOs);
    const [error, setError] = React.useState('');
    const withLink = (to, children) => <Link to={to}>{children}</Link>;
    const optionsModule = [{id: 1, label: 'Corretiva'}, {id: 2, label: 'Preventiva'}]
    function formatDate() {
        let data = new Date();
        let dia = String(data.getDate()).padStart(2, '0');
        let mes = String(data.getMonth() + 1).padStart(2, '0');
        let ano = data.getFullYear();
        let dataAtual = `${dia}${mes}${ano}`;
        return dataAtual;
    }
    const download = () => {
        try {
            if (!initialDate && !finalDate) {
                return [
                    setError('Preencha uma das datas.'),
                    setTimeout(() => {
                        setError('');
                    }, 3000)
                ];
            }
            getTaskExport(initialDate, finalDate, moduleOs).then((resp) => {
                let blob = new Blob([resp.data], { type: 'application/vnd.ms-excel' });
                let link = URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.download = 'file.xlsx';
                a.href = link;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setSuccess('Logs gerados com sucesso.');
                setTimeout(() => {
                    setSuccess('');
                }, 2000);
            });
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <>
            <MainCard sx={{ height: '100%' }} xs={12} md={12} sm={12} container>
                <Grid sx={{ mb: 10 }} xs={12} md={12} sm={12} container>
                    <h1
                        style={{
                            font: 'normal normal bold 35px/44px Myriad Pro',
                            letterSpacing: '0px',
                            color: 'var(--unnamed-color-015641)',
                            color: 'black',
                            opacity: 1,
                            padding: 15,
                            marginLeft: '2%'
                        }}
                    >
                        Exportação de Chamados
                    </h1>
                    <hr style={{ width: '95%', marginTop: 0 }}></hr>
                </Grid>
                {error || success ? (
                    <Snackbar open={true} autoHideDuration={6000}>
                        <Alert
                            severity={error ? 'error' : success ? 'success' : ''}
                            sx={{
                                width: '100%',
                                backgroundColor: error ? 'red' : success ? 'green' : 'orange',
                                color: '#FFF'
                            }}
                        >
                            {error ? error : success ? success : ''}
                        </Alert>
                    </Snackbar>
                ) : (
                    ''
                )}
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                    <Grid item xs={12} sm={2} sx={{ marginTop: 3 }}>
                        <TextField
                            fullWidth
                            id="outlined-initialDate"
                            type="date"
                            label="De"
                            value={initialDate}
                            onChange={(e) => dispatch({ type: 'SET_INITIALDATE_REPORT_TASK_FILTER', payload: e.target.value })}
                            name="initialDate"
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2} sx={{ marginTop: 3 }}>
                        <TextField
                            fullWidth
                            id="outlined-finalDate"
                            type="date"
                            label="Até"
                            value={finalDate}
                            onChange={(e) => dispatch({ type: 'SET_FINALDATE_REPORT_TASK_FILTER', payload: e.target.value })}
                            name="finalDate"
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2} sx={{ marginTop: 3 }}>
                        <TextField
                            fullWidth
                            select
                            label="Tipo"
                            id="moduleOS"
                            type="text"
                            value={moduleOs}
                            name="moduleOS"
                            onChange={(e) => dispatch({ type: 'SET_MODULEOS_REPORT_TASK_FILTER', payload: e.target.value })}
                        >
                            {optionsModule.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={6} sm={1} sx={{ mt: 4 }}>
                        <AnimateButton>
                            <Button variant="contained" color="primary" onClick={download}>
                                Baixar
                            </Button>
                        </AnimateButton>
                    </Grid>
                    <Grid item xs={6} sm={1} sx={{ mt: 4, ml: -5 }}>
                        <AnimateButton>
                            <Button variant="contained" color="error" onClick={(e) => dispatch({ type: 'SET_CLEAR_REPORT_TASK_FILTER' })}>
                                Limpar
                            </Button>
                        </AnimateButton>
                    </Grid>
                </Grid>
            </MainCard>
        </>
    );
};

export default ReportTask;
